body {
  position: fixed;
  bottom: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #2C2F35 0%, #1A1F2B 100%);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: calc(100vh + 1px);
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
